@import "../core/mixins";
@import "../core/placeholders";
@import "../core/variables";
@import "modules/layout";
#root {
	height: 100%;
}
.login-wrapper{
	height: 100%;
	background-color: $color-primary;
	& > * {
		height: 100%;
	}
	.background{
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-size: cover!important;
		background-position: top center!important;
		background-repeat: no-repeat!important;
		.backdrop {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgba(255, 255, 255, .2);
			top: 0;
			left: 0;
			z-index: $zIndex-1;
		}
		& > img{
			height: 100%;
			opacity: 0.6;
		}
	}
	.login-container{
		width: 100%;
		height: 100%;
		display: block;
		position: relative;
		float:right;
		padding: 15px;
		.environment-name-login{
			color: #D0102C;
			font-weight: bold;
			font-size: 25px;
			padding-top: 5px;
		}
	}
	.login-mobile {
		padding-top: 0px !important;
		margin-top: 0px !important;
	}
	.bg-caption{
		width: 100%;
		position: relative;
		z-index: $zIndex-2;
		@include vertical($start-color: rgba(0,0,0, .1), $end-color: rgba(0,0,0, .3), $start-percent: 0%, $end-percent: 100%);
	}
}
.register-container{
	width: 550px;
	margin: auto;
	height: 100%;
}


/* Responsive handlers : Login
------------------------------------
*/

@media (max-width: 768px) {
	.login-wrapper{
		.login-container{
			width: 100%;
			padding: 15px;
		}
	}
	.register-container{
		width: 100%;
		padding: 15px;
	}
}

@media only screen and (max-width : 321px) {
	.login-wrapper{
		.login-container{
			width: 100%;
		}
	}
}

@media all and (max-width: 370px) {
	.col-sx-12 {
	  flex: 0 0 100%;
	  max-width: 100%;
	}
	.login-wrapper .text-sx-center {
	  text-align: center !important;
	}
  }