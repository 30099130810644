.reactstrap-confirm.passwordless {
  .modal-header {
    border-bottom: none;

    .md-header {
      display: flex;
      justify-content: space-between;
      margin-left: 25px;
      margin-top: 15px;

      img {
        margin-right: 5px;
      }

      p {
        align-content: center;
        text-align: left;
        margin: 0;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: normal;
      }
    }
  }

  .modal-body {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      margin: 0;
    }
  }

  .pwdl-scanqr-message {
    text-align: center;
  }

  .modal-footer {
    border-top: none;
    align-self: center;
    margin-bottom: 20px;
  }
}
