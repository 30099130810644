.terms_of_use-wrapper {
  position: relative;
  background-color: #fff;
  min-height: 100%;

  .title-page {
    text-align: center;
    width: 100%;
    margin: 50px 0 30px;
    font-size: 20px;
    color: #000;
  }

  .content {
    p {
      font-size: 16px;
      line-height: 40px;
      color: #000;
      white-space: pre-wrap;
      padding-bottom: 20px;
    }
  }

  .content_en_US {
    p {
      font-size: 18px;
    }
  }

  .mobile-content {
    font-size: 16px;
    line-height: 20px;
    // white-space: wrap;
    text-align: justify;
    padding: 0 15px 15px 15px;
    overflow-y: scroll;
    color: #000;
    height: calc(100vh - 165px);
  }

  /* For WebKit browsers (Chrome, Safari) */
  .mobile-content::-webkit-scrollbar {
    width: 5px;
  }

  // .mobile-content.hide-scrollbar::-webkit-scrollbar {
  //   display: none;
  //   /* Hide scrollbar for WebKit browsers */
  // }

  // /* Optionally, you can make the scrollbar visible when hovering */
  // .mobile-content:hover::-webkit-scrollbar {
  //   display: block;
  //   /* Show scrollbar on hover */
  // }

  .mobile-content::-webkit-scrollbar-thumb {
    background: #c6c9c5;
    border-radius: 10px;
    border: 1px solid #f1f1f1;
    /* Creates padding around the thumb */
  }

  .mobile-title {
    text-align: center;
    width: 100%;
    margin: 20px 0 20px;
    font-size: 20px;
    color: #000;
  }

  div.btn-agree-terms {
    position: absolute;
    bottom: 0px;
    margin-bottom: 30px;
    left: calc(100%/2 - 135px);
    width: 270px;
  }

  .btn-agree-terms input[type='checkbox'] {
    width: 20px;
    height: 20px;
  }

  .btn-agree-terms {
    .btn {
      border-radius: 15px !important;
    }

    .form-check-input {
      margin-left: -2.25rem;
      margin-top: 7px;
    }
  }
}

@media (min-width: 768px) {
  .terms_of_use-wrapper {
    .content {
      margin: 0 50px;
    }
  }
}